import { React, useState, useEffect, API_URL, Button, Row, Modal, api } from "../../../services/common";
import { Card, Container } from 'react-bootstrap';
import TutorSidebar from '../TutorSidebar/TutorSidebar';
import DateTimePicker from 'react-datetime-picker';
import Multiselect from 'multiselect-react-dropdown';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import "./TutorCalender.css"

const TutorCalendar = () => {
    const [show, setShow] = useState(false);
    const [value, onChange] = useState(new Date());
    const [details, setDetails] = useState({});
    const [date, setDate] = useState(new Date());
    const [ind, setIndex] = useState(0);
    const [editclass, setEditClass] = useState(true);
    const [schedule, setSchedule] = useState({});
    const [updateClass, setUpdateClass] = useState(false);
    const [updateclassshow, setUpdateButton] = useState(false);
    const [cancelclassshow, setCancelButton] = useState(false);
    const [cancelClass, setcancelClass] = useState(false);
    const [joinclass, setJoinClass] = useState(false);
    const [classes, setClasses] = useState([]);
    const [options, setOptions] = useState([]);
    const [courses, setCourses] = useState([]);
    const [students, setStudents] = useState([]);
    const [course, setCourse] = useState('');
    const [loading, setLoader] = useState(false);
    const [link, setLink] = useState("");
    var Loader = require('react-loader');


    const changeDate = (date) => {
        setLoader(true);
        let id = localStorage.getItem('uid');
        setDate(date);
        api.get(API_URL + "/tutors/view-schedule/" + id + "?date=" + date).then((response) => {
            setLoader(false);
            response.data.forEach((res) => {
                res.date_format = new Date(res.date).toLocaleString(undefined, { timeZone: 'Asia/Kolkata', hour12: true, hour: 'numeric', minute: 'numeric' });
            }
            )
            setClasses(response.data);
        })
    }

    const openUpdate = (index) => {
        setIndex(index);
        setUpdateClass(true);
        let selectedSchedule = {
            course: [],
            date: new Date(classes[index].date),
            studentIds: classes[index].studentIds,
        }
        selectedSchedule["course"].push(classes[index].course);
        setStudents(classes[index].studentIds);
        setSchedule(selectedSchedule);
    }

    const cancelSchedule = () => {
        let slotId = classes[ind].slotId;
        let id = localStorage.getItem('uid');
        setLoader(true);
        api.delete(API_URL + "/tutors/cancel-schedule/" + id + "/" + slotId).then((response) => {
            setLoader(false);
            handleClose();
            changeDate(date);
        })
    }
    const dontCancel = () => {
        setcancelClass(false);
        setUpdateClass(true);
    }
    const updateSchedule = () => {
        setLoader(true);
        let id = localStorage.getItem('uid');
        let req_body = {
            date: value,
            studentIds: students,
            course: course,
        }
        let slotId = classes[ind].slotId
        api.put(API_URL + "/tutors/update-schedule/" + id + "/" + slotId, req_body).then((response) => {
            setLoader(false);
            handleClose();
            changeDate(date);

        })
    }
    const addSchedule = () => {
        setLoader(true);
        let id = localStorage.getItem('uid');
        let req_body = {
            date: value,
            studentIds: students,
            course: course,
        }
        api.post(API_URL + "/tutors/add-schedule/" + id, req_body).then((response) => {
            setLoader(false);
            handleClose();
            changeDate(date);

        })
    }

    const onRemoveStudent = (selectedList, removedItem) => {
        setStudents(selectedList);
    }
    const onSelectStudent = (selectedList, selectedItem) => {
        setStudents(selectedList);

    }
    const cancelClassPopup = () => {
        setcancelClass(true);
        setShow(false);
        setUpdateClass(false);
    }
    const onSelectCourse = (selectedList, selectedItem) => {
        setCourse(selectedItem);
    }
    const onRemoveCourse = (selectedList, removedItem) => {
    }
    const getStudents = () => {
        var id = localStorage.getItem('uid');
        api.get(API_URL + "/tutors/view-tutor/" + id).then((response) => {
            setOptions(response.data.students);
            setCourses(response.data.courses.split(","))
            setLink(response.data.link);
            let obj = {
                name: response.data.name,
                image: response.data.image
            }
            setDetails(obj);
        });
    }
    useEffect(() => {
        getStudents();
        setDate(date.setHours(0, 0, 0, 0));
        changeDate(date);
    }, []);
    const handleClose = () => {
        setShow(false);
        setUpdateClass(false);
        setcancelClass(false);

    }
    const openPopup = () => {
        setShow(true);
    }
    return (
        <div>
            <div className="row">
                <div className="col-4 general-setting">
                    <TutorSidebar name={details.name} image={details.image}></TutorSidebar>
                </div>
                {
                    loading && <Loader loaded={false}>
                    </Loader>

                }
                <Modal show={cancelClass} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Are You Sure You Want to Cancel the Class</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button onClick={() => cancelSchedule()}>
                            Yes
                        </Button>
                        <Button onClick={() => dontCancel()}>
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={updateClass} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Update Class</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>

                            <Row>
                                Course
                                <Multiselect disabled={editclass}
                                    options={courses} // Options to display in the dropdown// Preselected value to persist in dropdown
                                    singleSelect={true} selectedValues={schedule.course} isObject={false} placeholder="Select Course "
                                    onSelect={onSelectCourse} onRemove={onRemoveCourse}// Property name to display in the dropdown options
                                />


                            </Row>
                            <Row>
                                Start Time
                                <DateTimePicker onChange={onChange} value={schedule.date} disabled={editclass} />
                            </Row>

                            <Row>
                                Students

                                <Multiselect
                                    options={options} selectedValues={schedule.students} disabled={editclass}// Options to display in the dropdown// Preselected value to persist in dropdown
                                    displayValue="name" onSelect={onSelectStudent} onRemove={onRemoveStudent}  // Property name to display in the dropdown options
                                />

                            </Row>




                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        {editclass && <Button onClick={() => setEditClass(false)}>Edit Class</Button>}
                        {updateclassshow && <Button onClick={() => updateSchedule()}>Update Class</Button>}
                        {cancelclassshow} <Button onClick={() => cancelClassPopup()}>Cancel Class</Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Schedule Class</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                Course
                                <Multiselect
                                    options={courses} // Options to display in the dropdown// Preselected value to persist in dropdown
                                    singleSelect={true} isObject={false} placeholder="Select Course"
                                    onSelect={onSelectCourse} onRemove={onRemoveCourse}// Property name to display in the dropdown options
                                />
                            </Row>
                            <Row>
                                Start Time
                                <DateTimePicker onChange={onChange} value={value} />
                            </Row>
                            <Row>
                                Students

                                <Multiselect
                                    options={options} // Options to display in the dropdown// Preselected value to persist in dropdown
                                    displayValue="name" onSelect={onSelectStudent} onRemove={onRemoveStudent}  // Property name to display in the dropdown options
                                />
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={addSchedule}>Schedule Class</Button>
                    </Modal.Footer>
                </Modal>


                <div className="col-4 general-setting">
                    <Calendar onChange={changeDate} >
                    </Calendar>
                    <Button onClick={openPopup} className="schedule-class">Schedule a Class</Button>
                </div>
                <div className="col-4 general-setting">
                    <div className="schedule-list">
                        {classes.map((classe, index) => (
                            <div key={index}>
                                <Card className="slot-card" onClick={() => openUpdate(index)}>
                                    <Card.Body>
                                        <Card.Text><span className="class-headers">Time:</span> {classe.date_format}  {classe.course && <span> <span className="class-headers">Course:</span> {classe.course}</span>}</Card.Text>
                                        <Card.Text>
                                            View Details
                                        </Card.Text>
                                        <Card.Text>
                                            {joinclass && <Button onClick={() => openUpdate(index)}>Join Class</Button>}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                        )
                        )
                        }
                    </div>
                    {
                        classes.length == 0 && <div className="no-class">No Classes Scheduled</div>
                    }


                </div>


            </div>
        </div>

    )
}

export default TutorCalendar
