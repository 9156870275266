import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert, Row, Col } from "react-bootstrap"
import { useAuth } from "./AuthContext";
import { Link } from "react-router-dom";
import "./Auth.css";


// Forget password page
const ForgotPassword = () => {
    const emailRef = useRef()
    const { resetPassword } = useAuth()
    const [error, setError] = useState("")
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            setMessage("")
            setError("")
            setLoading(true)
            await resetPassword(emailRef.current.value)
            setMessage("Check your inbox for further instructions")
        } catch {
            setError("Failed to reset password")
        }
        setLoading(false)
    }

    return (
        <>
            <Row className="center">
                <Col className="box">
                    <h1 className="auth-box">Join Now to Start your Journey in Music</h1>
                </Col>
                <Col className="auth-card">
                    <Card className="auth-card">
                        <Card.Body className="form-fields">
                            <h2 className="text-center">Password Reset</h2>
                            {error && <Alert variant="danger">{error}</Alert>}
                            {message && <Alert variant="success">{message}</Alert>}
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="fields" >
                                    <Form.Control type="email" placeholder="Email ID" ref={emailRef} required />
                                </Form.Group>
                                <Button disabled={loading} className="w-100 submit" type="submit">
                                    Reset password
                                </Button>
                            </Form>
                            <div className="text-center">
                                <Link to="/login" className="signup-text">Back to login</Link>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>

    )
}

export default ForgotPassword;