import { React, useState, useEffect, api, API_URL } from "../../../services/common";
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import StudentSidebar from '../StudentSidebar/StudentSidebar';
import "./StudentPayments.css";
var Loader = require('react-loader');



const StudentPayments = () => {
    const [studentPayment, setStudentpayment] = useState([]);
    const [loading, setLoader] = useState(true);
    function Row(props) {
        const { row } = props;
        const [open, setOpen] = React.useState(false);


        return (
            <React.Fragment>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.name}
                    </TableCell>
                    <TableCell align="right">{row.course}</TableCell>
                    <TableCell align="right">{row.class_attended} / {row.total_class}</TableCell>
                    {/* <TableCell align="right">{row.carbs}</TableCell>
          <TableCell align="right">{row.protein}</TableCell> */}
                </TableRow>
            </React.Fragment>
        );
    }


    useEffect(() => {

        let student_id = localStorage.getItem('uid');

        api.get(API_URL + '/students/payment_logs?student_id=' + student_id).then((response) => {
            setLoader(false);
            setStudentpayment(response.data);

        });
    }, []);

    return (
        <div className="row">
            <div className="col-4">
                <StudentSidebar></StudentSidebar>
            </div>
            <div className="col-8" id="payment_history">
                {
                    loading && <Loader loaded={false}>
                    </Loader>

                }

                <table className="payment_history">
                    <thead>
                        <tr>
                            <th className="pay_head">Date</th>
                            <th className="pay_head">Amount</th>
                            <th className="pay_head">Instructor</th>
                            <th className="pay_head">Courses</th>
                            <th className="pay_head">Classes</th>

                        </tr>
                    </thead>
                    <tbody>
                        {studentPayment.map((stu_pay) => (
                            <tr>
                                <td>{stu_pay.date}</td>
                                <td>{stu_pay.amount}</td>
                                <td>{stu_pay.tutor_name}</td>
                                <td>{stu_pay.course}</td>
                                <td>{stu_pay.classes}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="col-8" id="students">
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>Tutor Name</TableCell>
                                <TableCell align="right">Course</TableCell>
                                <TableCell align="right">Classes Attended</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {studentPayment.map((tutor, index) => (
                                <Row key={index} row={tutor} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
            </div>
   
    )
}

export default StudentPayments
