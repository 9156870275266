import { React, useEffect, useState, api, API_URL } from "../../../services/common"
import StudentSidebar from '../StudentSidebar/StudentSidebar';
import date_logo from "../../../assets/images/date_logo.png"
import time_logo from "../../../assets/images/time_logo.png";
import "./StudentClasses.css"

const StudentClasses = () => {
    const [shedule, setSchedule] = useState([]);
    const getClasses = () => {
        var id = localStorage.getItem('uid');
        api.get(API_URL + "/students/view-schedule/" + id).then((response) => {
            const temp = [...response.data];
            setSchedule(temp);
            response.data.forEach((res) => {
                res.date = new Date(res.date).toLocaleString(undefined, { timeZone: 'Asia/Kolkata', hour12: true, hour: 'numeric', minute: 'numeric' });
            }
            )
        })

    }
    useEffect(() => {
        getClasses();
    }, [])
    return (
        <div>

            <div id="page">
                <div id="middle_part">
                    <StudentSidebar></StudentSidebar>

                    <div id="classes_part">
                        <table>
                            <thead>
                                <tr>
                                    <th><h2>All Classes</h2></th>
                                    <th>

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {shedule.map((shed) => (
                                    <tr>
                                        <td>
                                            <div id="ins_int">
                                                <div><b>{shed.tutor_name}</b> </div>
                                                <div><b>{shed.course} </b></div>
                                                <br></br>
                                            </div>
                                            <div id="table_data">
                                                <img id="date_time_logo" src={date_logo}></img>
                                                {shed.date}
                                                <br />
                                                <img id="date_time_logo" src={time_logo}></img>
                                                {shed.time}
                                                <p> Attendance: {shed.attended && <b>Present {shed.time_attended}</b>}
                                                    {!shed.attended && <b>Absent</b>}
                                                </p>


                                            </div>
                                        </td>
                                        <td>

                                            {(shed.attended && !shed.demo) && <p>Paid Classs</p>}
                                            {shed.demo && <p>Demo Class</p>}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StudentClasses
