import { React, useState, useEffect, API_URL, api } from "../../../services/common";
import TutorSidebar from '../TutorSidebar/TutorSidebar';
import "./TutorProfile.css";


const TutorProfile = () => {
    const [id, setId] = useState('');
    const [courses, setCourses] = useState([]);
    const [details, setDetails] = useState({});

    const getProfile = () => {
        var id = localStorage.getItem('uid');
        api.get(API_URL + "/tutors/view-tutor/" + id).then((response) => {
            setCourses(response.data.experiences)
            let obj = {
                name: response.data.name,
                phone: response.data.phone,
                about: response.data.about,
                image: response.data.image,
            }
            setDetails(obj);
        });
    }
    useEffect(() => {
        setId(localStorage.getItem('uid'));
        getProfile();
    }, []);


    return (
        <div className="row">
            <div className="col-4 side-bar">
                <TutorSidebar image={details.image} name={details.name}></TutorSidebar>
            </div>
            <div className="basic col-4">
                <div id="heading_1">
                    <div id="heading_2">
                        <h3 id="h1">Basic Details</h3>
                        <h5 id="h1">(nformation about yourself)</h5>
                    </div>
                    {/* <a href="#"><img id="edit_sign" src={edit_sign}></img></a> */}
                </div>
                <div id="input_field">

                    <img id="student_profile" src={details.image}></img>
                    <input id="input" type="name" text="text" placeholder='Name' value={details.name} disabled></input>
                    <input id="input" type='phonenumber' text="numbers" placeholder='Phone Number' value={details.phone} disabled></input>
                    {/* <input id="input" type='links' text='text' placeholder='Social Media Links' ></input> */}
                    <textarea className="about" id="input" type='links' text='text' placeholder='About Yourself' value={details.about} disabled></textarea>
                </div>
            </div>
            <div className="col-4">
                <div id="heading_1">
                    <div id="heading_2">
                        <h3 id="h1">Courses</h3>
                        <h5 id="h1">(Your Professional qualifications)</h5>
                    </div>
                    {/* <a href="#"><img id="edit_sign" src={edit_sign}></img></a>  */}
                </div>



                {courses.length > 0 && courses.map((course) => (

                    <div>
                        <p><b>{course.course} </b></p>
                        {/* <label>Education</label> */}
                        <input id="input" type="text" text="text" placeholder='Education' defaultValue={course.education} disabled></input>
                        <input id="input" type="text" text="text" placeholder='Years of Experience' defaultValue={course.experience} disabled></input>
                        <textarea id="input" type="text" text="text" placeholder='Accolades' defaultValue={course.achievements} disabled></textarea>
                        <br></br>
                        <br></br>
                    </div>
                ))
                }


            </div>
        </div>


    )
}

export default TutorProfile
