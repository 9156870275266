import { React, useState, useEffect, api, API_URL, Button } from "../../../services/common";
import StudentSidebar from '../StudentSidebar/StudentSidebar';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import "./StudentCalendar.css"
var Loader = require('react-loader')

const StudentCalender = () => {
    const [date, setDate] = useState(new Date());
    const [schedule, setSchedule] = useState([]);
    const [classes, setClasses] = useState([]);
    const [loading, setLoader] = useState(false);

    const changeDate = (date) => {
        let id = localStorage.getItem('uid');
        setDate(date);
        const params = {
            date: date,
            student: true
        };

        setLoader(true)
        api.get(API_URL + "/students/view-schedule/" + id, { params }).then((response) => {
            setLoader(false);
            const temp = [...response.data];
            setSchedule(temp);
            response.data.forEach((res) => {
                let time = new Date(res.date);
                res.showLink = false;
                if (new Date((time).setHours(time.getHours() - 0.5)) <= new Date() && new Date() <= new Date((time).setHours(time.getHours() + 1)))
                    res.showLink = true;
                res.date = new Date(res.date).toLocaleString(undefined, { timeZone: 'Asia/Kolkata', hour12: true, hour: 'numeric', minute: 'numeric' });
            }
            )
            setClasses(response.data);
        })

    }
    useEffect(() => {
        changeDate(date);
    }, []);

    return (
        <div className="row">
            <div className="col-4">
                <StudentSidebar></StudentSidebar>
            </div>
            <div className="col-4" id="calendar">
                {
                    loading && <Loader loaded={false} ></Loader>
                }
                <Calendar onChange={changeDate} value={date}>

                </Calendar>

            </div>
            
            <div className="col-4" id="class_details">
                {classes.map((classe) => (
                    <div >

                        <div className="tutor_details">
                            <h5>{classe.course} Class by {classe.tutor}</h5>
                            <h5>Time: {classe.date}</h5>
                            {
                                classe.showLink && <a href={classe.link} target="_blank"><Button>Class Link</Button> </a>
                            }
                            {
                                !classe.showLink && <Button>Class Details</Button>
                            }
                        </div>
                    </div>

                )
                )

                }
                {
                    classes.length == 0 && <div><h3 className="no-class">No Classes Scheduled</h3></div>
                }

            </div>
        </div>
    )
}

export default StudentCalender
