import { api, API_URL, Button } from "../../../services/common";
import "./Payment.css";
import { NotificationManager } from 'react-notifications';

function Payment(props) {
    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function displayRazorpay() {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        const result = await api.post(API_URL + "/payments/make_payment/mw9fhqNK8RREug5FYlURLpump7p2", {
            amount: props.amount * 100,
            currency: "INR",
            tutor_id: props.tutor_id,
            classes: props.classes
        });

        if (!result) {
            alert("Server error. Are you online?");
            return;
        }

        const { amount, id: order_id, currency } = result.data;

        const options = {
            key: "rzp_test_ANCrJG4YVIfOkl", // Enter the Key ID generated from the Dashboard
            amount: amount.toString(),
            currency: currency,
            name: "Soumya Corp.",
            description: "Test Transaction",
            order_id: order_id,
            handler: async function (response) {
                debugger;
                const data = {
                    orderCreationId: order_id,
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                };
                await api.post(API_URL + "/payments/success_payment", data).then((response) => {
                    const payment_data = {
                        name: props.tutor_name,
                        tutor_id: props.tutor_id,
                        amount: props.amount,
                        classes: props.classes,
                        course: props.course,
                        student_id: localStorage.getItem('uid')
                    }
                    api.post(API_URL + "/payments/add_payment_log", payment_data).then((response) => {
                        api.post(API_URL + "/students/add-student-classes", payment_data).then((response) => {
                            NotificationManager.success("Payment Successful.");
                        });

                    });
                }
                );


            },
            prefill: {
                name: "Soumya Dey",
                email: "SoumyaDey@example.com",
                contact: "9999999999",
            },
            notes: {
                address: "Soumya Dey Corporate Office",
            },
            theme: {
                color: "#61dafb",
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.on('payment.failed', function (response) {
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.order_id);
            alert(response.error.metadata.payment_id);
        });
        paymentObject.open();
    }
    return (
        <div>
            <Button className="App-link pay-button" onClick={displayRazorpay}>
                Pay ₹ {props.amount}
            </Button>


        </div>

    );
}

export default Payment;