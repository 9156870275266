import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import Header from './components/Common/Header/Header';
import Footer from './components/Common/Footer/Footer';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import TutorsList from './components/BookClass/TutorList/TutorList';
import { AuthProvider } from "./components/Auth/AuthContext"
import Login from './components/Auth/Login';
import Signup from './components/Auth/Signup';
import PhoneAuth from './components/Auth/MobileLogin';
import ForgotPassword from './components/Auth/ForgotPassword';
import StudentProfile from './components/StudentPanel/StudentProfile/StudentProfile';
import StudentClasses from './components/StudentPanel/StudentClasses/StudentClasses';
import StudentCalender from './components/StudentPanel/StudentCalendar/StudentCalendar';
import StudentPayments from './components/StudentPanel/StudentPayments/StudentPayments';
import StudentTutors from './components/StudentPanel/StudentTutors/StudentTutors';
import TutorProfile from './components/TutorPanel/TutorProfile/TutorProfile';
import TutorCalendar from './components/TutorPanel/TutorCalendar/TutorCalender';
import TutorStudentLogs from './components/TutorPanel/TutorStudentLogs/TutorStudentLogs';
import TutorStudents from './components/TutorPanel/TutorStudents/TutorStudents';
import reportWebVitals from './reportWebVitals';
import 'react-notifications/lib/notifications.css';


const container = document.getElementById('root');
const root = createRoot(container); // createRoot(con

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <Header />
        <Routes>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="tutors" element={<TutorsList />} />
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
          <Route path = "login_via_phone" element = {<PhoneAuth />}/>
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="tutor-panel/calendar" element={<TutorCalendar />} />
          <Route path="tutor-panel/home" element={<TutorProfile />} />
          <Route path="tutor-panel/students" element={<TutorStudents />} />
          <Route path="tutor-panel/student-list" element={<TutorStudentLogs />} />
          <Route path="students/home" element={<StudentProfile />} />
          <Route path="students/calendar" element={<StudentCalender />} />
          <Route path="students/tutors" element={<StudentTutors />} />
          <Route path="students/classes" element={<StudentClasses />} />
          <Route path="students/payments" element={<StudentPayments />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
    {/* <App /> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
