import { React, useEffect, useState, api, API_URL, Button, Row, Col, Modal } from "../../../services/common";
import { Card, Container } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import ReactStars from "react-rating-stars-component";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Payment from '../Payment/Payment';
import Multiselect from 'multiselect-react-dropdown';
import "./TutorList.css";

var Loader = require('react-loader');
const TutorList = () => {
    const [tutors, setTutors] = useState([]);
    const [tutorid, setTutorId] = useState("");
    const [tutor, setTutor] = useState({});
    const [show, setShow] = useState(false);
    const [demo, setDemo] = useState(false);
    const [slots, setSlots] = useState([]);
    const [bookclass, setBookClass] = useState(false);
    const [demoslot, setDemoSlots] = useState("");
    const [classcount, setClassCount] = useState(8);
    const [amount, setAmount] = useState(2000);
    const [courses, setCourses] = useState([]);
    const [course, setCourse] = useState("");
    const [loading, setLoader] = useState(true);

    const navigate = useNavigate();

    const handleClose = () => {
        setCourse("");
        setShow(false);
        setDemo(false);
        setBookClass(false);
    }
    const onSelectCourse = (selectedList, selectedItem) => {
        setCourse(selectedItem);
    }
    const showClass = () => {
        if (localStorage.getItem('uid') && localStorage.getItem('is_student')) {
            setBookClass(true);
            setShow(false);
            setDemo(false);
        }
        else {
            NotificationManager.error("Please Login/Signup to Continue");
        }

    }
    const updateClassCount = (event) => {
        let count = event.target.value;
        setClassCount(count);
        setAmount(250 * count)
    }
    const demoSlot = (slot) => {
        setDemoSlots(slot);
    }
    const viewAvailableSlots = () => {
        if (localStorage.getItem('uid') && localStorage.getItem('is_student')) {
            const params = {
                tutorId: tutorid,
            };
            setLoader(true);
            api.get(API_URL + '/tutors/view-available-schedule/' + tutorid).then((response) => {
                setLoader(false);
                setSlots(response.data);
                handleDemo();
                setShow(false);
            });
        }
        else {
            NotificationManager.error("Please Login/Signup to Continue");
        }

    }
    const handleShow = () => setShow(true);
    const bookSlot = () => {
        if (localStorage.getItem('uid')) {
            let studentId = localStorage.getItem('uid');
            setLoader(true);
            const params = {
                slotId: demoslot.id,
                student: {
                    id: studentId,
                }
            }
            api.post(API_URL + "/tutors/book-schedule/" + tutorid, params).then((response) => {
                setLoader(false);
                if (response.message)
                    NotificationManager.success("Slot Booked ! See you at the Demo Class");
                handleClose();
                navigate("/students/home");

            });

        }
        else {
            NotificationManager.error('Please Login to Continue', () => {
                alert('callback');
            });
        }

    }
    const handleDemo = () => setDemo(true);
    const viewTutors = function (id) {
        setTutorId(id);
        setLoader(true);
        api.get(API_URL + "/tutors/view-tutor/" + id).then((response) => {
            setLoader(false);
            let obj = {
                name: response.data.name,
                about: response.data.about,
                courses: response.data.courses,
                experience: response.data.experience,
                image: response.data.image,
                rating: response.data.rating,
                reviews: response.data.reviews,
                video: response.data.video
            }
            setTutor(obj);
            setCourses(response.data.courses.split(','));
            // setCourse(course[0]);
            handleShow();
        })
    };

    useEffect(() => {
        api.get(API_URL + '/tutors/view-tutors').then((response) => {
            setLoader(false);
            setTutors(response.data);

        });
    }, []);



    return (
        <>

            <br></br>
            <br></br>
            {
                loading && <Loader loaded={false}>
                </Loader>

            }



            <Modal show={show} onHide={handleClose} size="lg">

                {/* <Modal.Header closeButton>
          <Modal.Title>Learn from </Modal.Title>
        </Modal.Header> */}
                <Modal.Body closeButton>
                    <Container>
                        <Row>
                            <Col className="Ex student-color" xs={8} md={8}>
                                <Row>
                                    <Col className="modal-head">
                                        <b>Experience:</b> {tutor.experience} yrs
                                    </Col>
                                    <Col className="modal-head">
                                        <b>Students:</b> {tutor.students}
                                    </Col>
                                    <Col className="modal-head">
                                        <b>Rating: </b>{tutor.rating}/5
                                    </Col>
                                </Row>
                                <br></br>
                                <Row className="tutor-about">  {tutor.about}</Row>

                                <br></br>
                                <Button onClick={() => viewAvailableSlots()}>Book Demo</Button>

                                <Button className="book-class" onClick={showClass} >Book Class</Button>
                            </Col>
                            <Col xs={4} md={4} className="image-box">

                                <img className="image-tutor" src={tutor.image}></img>

                                <Row className="tutor-name">
                                    <b>{tutor.name}</b>
                                </Row>
                                <Row className="tutor-name">
                                    {tutor.courses}
                                </Row>
                            </Col>
                        </Row>
                        <hr></hr>
                        <Row>

                            {
                                (tutor.reviews && tutor.reviews.length > 0) && <Col xs={6} md={6}>
                                    <b>Student Reviews ({tutor.reviews.length})</b>
                                    <Row className="reviews">

                                        {tutor.reviews.map((review, i) => (

                                            <div>
                                                <Row>
                                                    <Col>
                                                        <> {i + 1} . {review.student}</>
                                                    </Col>
                                                    <Col className="rate-star">
                                                        <ReactStars
                                                            count={5}
                                                            value={review.stars}
                                                            size={24}
                                                            activeColor="#ffd700" edit={false} isHalf={true}></ReactStars>
                                                    </Col>
                                                </Row>
                                                <Row className="review">
                                                    <i>"{review.review}"</i>
                                                </Row>

                                            </div>




                                        ))}


                                    </Row>

                                </Col>

                            }

                            <Col xs={6} md={6}>
                                <iframe width="360" height="250" src={tutor.video} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </Col>

                        </Row>





                    </Container>
                </Modal.Body>
            </Modal>


            <Modal show={bookclass} onHide={handleClose} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        {/* onSubmit={this.handleSubmit} */}
                        <Row>
                            <Row>
                                <label>
                                    Choose Subject
                                    <br></br>
                                    <Multiselect
                                        options={courses} // Options to display in the dropdown// Preselected value to persist in dropdown
                                        singleSelect={true} isObject={false} placeholder="Select Course"
                                        onSelect={onSelectCourse}
                                    />
                                </label>

                                <label>
                                    No of Classes
                                    <br></br>
                                    <input type="number" value={classcount} onChange={updateClassCount} min="8" className='payment' />
                                </label>
                                <br>
                                </br>

                            </Row>
                            <Row>
                                <label>
                                    Amount
                                    <br></br>
                                    <input type="number" disabled value={amount} placeholder="Amount" className='payment'></input>
                                </label>
                                <br></br>
                                <br></br>


                            </Row>

                            <br></br>
                            {
                                course == "" && <p className="select-course">*Please Select Course to Continue</p>
                            }

                            {
                                course != "" && <div onClick={handleClose}>  <Payment amount={amount} tutor_name={tutor.name} tutor_id={tutorid} classes={classcount} course={course}></Payment>
                                </div>
                            }




                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>


            <Modal show={demo} onHide={handleClose} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>Available Slots</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col className="slot-popup" id="items">
                                {slots.map((slot, i) => (
                                    <Card className="slot-card" style={{ width: '10rem' }} onClick={() => demoSlot(slot)}>
                                        <Card.Body className={demoslot.date == slot.date ? 'slot-select' : 'slot-card'} >
                                            <Card.Title className="slot-date">{slot.date}</Card.Title>
                                            <Card.Text>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                ))}
                                {
                                    slots.length == 0 && <h5>No Slots Available</h5>
                                }

                            </Col>
                        </Row>
                        <Row>
                            {
                                slots.length > 0 && <Button className="book-demo" onClick={bookSlot}>Book Demo</Button>
                            }

                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>



            <div id="instructors" className="instruct">
                <div id="items">


                    {tutors.map(({ id, name, courses, ratings, image, experience, rating }, i) => (
                        <div key={i} id="tutor_photo">
                            <Card id="tutor_cards" onClick={() => viewTutors(id)}>
                                <Card.Img id="tutor_image" variant="left" src={image} />
                                <Card.Body id="tutorcard_body">
                                    <Card.Title><h4>{name}</h4></Card.Title>
                                    <Card.Title><h6><b>Classes:</b> {courses}</h6>
                                    </Card.Title>
                                    <Card.Title><h6><b>Experience:</b> {experience} yrs</h6>
                                    </Card.Title>
                                    <Card.Title>
                                        <ReactStars
                                            count={5}
                                            value={rating}
                                            size={24}
                                            activeColor="#ffd700" edit={false} isHalf={true}></ReactStars>

                                    </Card.Title>
                                    <Button>Book Demo</Button>
                                </Card.Body>
                            </Card>
                        </div>
                    ))}
                </div>
            </div>



            <br></br>
            <br></br>
            <NotificationContainer />
        </>
    )
}

export default TutorList
