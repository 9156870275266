import { Link } from 'react-router-dom';
import "./StudentSidebar.css";

const StudentSidebar = (props) => {
    return (
        <div className="sidebar">
            <img className="profile-sidebar-image" src={props.image}></img>
            <h3 className="profile-name">{props.name}</h3>
            <div className="student-sidebar">
                <Link to="/students/home" className="nav_link">
                    <span className="flex-display">
                        <i className="material-icons sidebar-icon">
                            account_circle</i>
                        <h4>Profile</h4>
                    </span>
                </Link>
                <Link to="/students/calendar" className="nav_link">
                    <span className="flex-display">
                        <i className="material-icons sidebar-icon">
                            calendar_month</i>
                        <h4>Calendar</h4>
                    </span>
                </Link>
                <Link to="/students/tutors" className="nav_link">
                    <span className="flex-display">
                        <i className="material-icons sidebar-icon">
                            groups</i>
                        <h4>Tutors</h4>
                    </span>
                </Link>
                <Link to="/students/classes" className="nav_link">
                    <span className="flex-display">
                        <i className="material-icons sidebar-icon">
                            history</i>
                        <h4>Class Logs</h4>
                    </span>
                </Link>
                <Link to="/students/payments" className="nav_link">
                    <span className="flex-display">
                        <i className="material-icons sidebar-icon">
                            payments</i>
                        <h4>Payment History</h4>
                    </span>
                </Link>
            </div>
        </div>
    )
}
export default StudentSidebar;