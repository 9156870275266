import { React, useState, Button, Row, Col } from "../../services/common"
import { useRef } from "react";
import { Form, Card, Alert } from "react-bootstrap"
import { useAuth } from "./AuthContext";
import { Link, useNavigate } from "react-router-dom"
import "./Auth.css";
import phone from "../../assets/images/phone-icon.webp";

// Log in page

const Login = (props) => {
    const emailRef = useRef();
    const passwordRef = useRef();
    const { login, getClaims } = useAuth();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const { googleSignIn } = useAuth();
    const navigate = useNavigate();
    const handleSignupClick = () => {
        debugger;
        // Call the callback function passed from the parent
        props.showSignup();
      };




    const googleLogin = () => {
        googleSignIn().then((result) => {
            claim();

            // This gives you a Google Access Token. You can use it to access the Google API.
            // const credential = GoogleAuthProvider.credentialFromResult(result);
            // const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;

            // IdP data available using getAdditionalUserInfo(result)
            // ...
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            // const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
        });
    }

    const claim = () => {
        getClaims().then((idTokenResult) => {
            localStorage.setItem('uid', idTokenResult.claims.user_id);
            if (idTokenResult.claims.tutor) {
                localStorage.setItem('is_tutor', 1);
                localStorage.setItem('is_student', 0);
                navigate("/tutor-panel/home");
            }
            else {
                localStorage.setItem('is_student', 1);
                localStorage.setItem('is_tutor', 0);
                navigate("/");
            }
        });

    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setError("")
            setLoading(true)
            await login(emailRef.current.value, passwordRef.current.value).then((response) => {
                claim();

            });

        } catch {
            setError("Failed to log in")
        }

        setLoading(false)
    }


    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };
    const openPhoneScreen = () => {
        navigate('/login_via_phone');
    };



    return (
        <>

            <Row className="center">
                <Col className="auth-card">
         
                            {error && <Alert variant="danger">{error}</Alert>}
                            <Form onSubmit={handleSubmit} className>
                                <div className = "social-logins">
                                <Button className="google-login" onClick={googleLogin} variant="dark">
                                    <span className="continue-with-google"><span class="google-icon"></span>Login with Google</span></Button>
                                <Button onClick={openPhoneScreen} className="google-login" variant="dark">
                                    <span className="continue-with-google"><span><img src={phone} class="phone-icon"></img></span>Login with OTP</span></Button>
                                </div>
          
                         
                                <div className="or-section">
                                    <hr className="line">
                                    </hr>
                                    <span className="or">Or use your email</span>
                                </div>
                                <Form.Group className="fields">
                                    <Form.Control className = "auth-fields" type="email" ref={emailRef} placeholder="Email" required />
                                </Form.Group>
                                <Form.Group className="fields">
                                    <Form.Control type={passwordShown ? "text" : "password"} ref={passwordRef} placeholder="Password" required />
                                    <i onClick={togglePassword} className={`eye fa ${passwordShown ? "fa-eye-slash" : "fa-eye"}`} />
                                </Form.Group>
                              
                                <Button variant="dark" size="lg" disabled={loading} className="w-100 submit" type="submit">
                                    Login
                                </Button>

                                {/* <Button className="google-login" variant="dark">
                                    <span className="continue-with-google" variant="dark"> <span>
                                        <img class="phone-icon" src={phone}></img></span>Continue with Phone Number</span></Button>    */}
                             <div className = "social-logins">
                             <div className="forgot">
                                    <Link to="/forgot-password" className="signup-text">Forgot Password?</Link>
                                </div>
                                <div className="text-center margin">
                                    Are You a NewBie? <Link onClick = {() => handleSignupClick() } className="signup-text">Get Started</Link>
                                </div>
                                </div>
                            </Form>


                </Col>

            </Row>
        </>
    )
}

export default Login;
