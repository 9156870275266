import { React, useState, useEffect, api, API_URL, Button } from "../../../services/common";
import StudentSidebar from '../StudentSidebar/StudentSidebar';
import edit_sign from "../../../assets/images/edit_sign.png"
import "./StudentProfile.css";

const StudentProfile = () => {
    const [profile, setProfile] = useState({});
    const [disabled, setDisabled] = useState(true);
    const [phone, setPhone] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const editProfile = () => {
        setDisabled(false);
    }
    const studentProfile = () => {
        let id = localStorage.getItem('uid');

        api.get(API_URL + "/students/student/" + id).then((response) => {
            setProfile(response.data);

        });
    }
    const saveChanges = () => {
        let req_body = {
            name: name,
            phone: phone,
            email: email,

        }
        let id = localStorage.getItem('uid');
        api.post(API_URL + "/students/student/" + id, { req_body }).then((response) => {
        })
    }
    useEffect(() => {
        studentProfile();
    }, []);


    return (
        <div className="row">
            <div className="col-4">
                <StudentSidebar></StudentSidebar>
            </div>
            <div className="col-4" id="basic_details">
                <div id="basic">
                    <div id="heading_1">
                        <div id="heading_2">
                            <h3 id="h1">Basic Details</h3>
                            <h4 id="h1">(Add information about yourself)</h4>
                        </div>
                        <a href="#"><img id="edit_sign" src={edit_sign} onClick={editProfile}></img></a>
                    </div>
                    <div id="input_field">
                        <img id="student_profile" src={profile?.image}></img>
                        <br></br>
                        <br></br>
                        <input id="input" type="name" text="text" placeholder=' Name' defaultValue={profile?.name} onChange={setName} disabled={disabled}></input>
                        <input id="input" type='name' text="text" placeholder='Email' defaultValue={profile?.email} onChange={setEmail} disabled={disabled}></input>
                        <input id="input" type='number' text="numbers" placeholder='Phone Number' defaultValue={profile?.phone} onChange={setPhone} disabled={disabled}></input>
                        <br></br>
                        <br></br>

                        {!disabled && <Button>Save Changes</Button>}

                    </div>

                </div>
                <br></br>
                <br></br>
            </div>
        </div>
    )
}

export default StudentProfile
