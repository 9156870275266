import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert, Row, Col } from "react-bootstrap"
import { useAuth } from "./AuthContext";
import { Link, useNavigate } from "react-router-dom";
import "./Auth.css";
import phone from "../../assets/images/phone-icon.webp";

// Sign Up page for using app and creating account
const Signup = (props) => {
    const nameRef = useRef();
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const code = useRef();
    const [enterPhone, setEnterPhone] = useState(false);
    const [codePopup, setCodePopup] = useState(false);
    const { signup, getClaims } = useAuth();
    const { googleSignIn } = useAuth();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
    const handleLoginClick = () => {
        // Call the callback function passed from the parent
        props.showLogin();
      };


    const navigate = useNavigate()

    const openPhoneScreen = () => {
        navigate('/login_via_phone');
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError("Passwords do not match")
        }

        try {
            setError("")
            setLoading(true)
            await signup(emailRef.current.value, passwordRef.current.value).then((response) => {
                getClaims().then((idTokenResult) => {
                    localStorage.setItem('uid', idTokenResult.claims.user_id);;

                    navigate("/")
                })
            }
            )

        } catch {
            setError("Failed to create an account")
        }

        setLoading(false)
    }
    const togglePassword = (type) => {
        if (type) {
            setConfirmPasswordShown(!confirmPasswordShown);
        }
        else {
            setPasswordShown(!passwordShown);
        }

    };



    return (
        <>
            <Row className="center">

                <Col className="auth-card">
                  
                            <h2 className="text-center">Sign Up</h2>
                            {error && <Alert variant="danger">{error}</Alert>}
                            <Form onSubmit={handleSubmit}>
                                <div className = "social-logins">
                                <Button className="google-login" onClick={googleSignIn} variant="dark">
                                    <span className="continue-with-google"><span class="google-icon"></span>Continue with Google</span></Button>
                                <Button onClick={openPhoneScreen} className="google-login" variant="dark">
                                    <span className="continue-with-google"><span><img src={phone} className="phone-icon"></img> </span>Continue with Phone Number</span></Button>
                                </div>


                                <div className="or-section">
                                    <hr className="line">
                                    </hr>
                                    <span className="or">Or signup </span>
                                </div>
                                <div>

                                <Form.Group className="fields">
                                    <Form.Control type="name" placeholder="Name" ref={nameRef} required />
                                </Form.Group >
                                <Form.Group className="fields">
                                    <Form.Control type="email" placeholder="Email" ref={emailRef} required />
                                </Form.Group >
                                <Form.Group className="fields">
                                    <Form.Control type={passwordShown ? "text" : "password"} placeholder="Password" ref={passwordRef} required />
                                    <i onClick={() => togglePassword(0)} className={`eye fa ${passwordShown ? "fa-eye-slash" : "fa-eye"}`} />
                                </Form.Group>

                                <Form.Group className="fields">
                                    <Form.Control type={confirmPasswordShown ? "text" : "password"} placeholder="Confirm Password" ref={passwordConfirmRef} required />
                                    <i onClick={() => togglePassword(1)} className={`eye fa ${confirmPasswordShown ? "fa-eye-slash" : "fa-eye"}`} />
                                </Form.Group>

                                <Button variant="dark" size="lg" disabled={loading} className="w-100 submit" type="submit">
                                    Continue
                                </Button>


                                </div>
                                

                                <div className="text-center margin">
                                    Already have an account? <Link onClick = {() => handleLoginClick() } className="signup-text">Login</Link>
                                </div>
                            </Form>
                </Col>
            </Row>
        </>
    )
}


export default Signup;