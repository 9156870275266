import React, { useContext, useState, useEffect } from "react"
import { auth } from "../../firebase";
import { GoogleAuthProvider, PhoneAuthProvider, RecaptchaVerifier } from "firebase/auth";

const provider = new GoogleAuthProvider();



const AuthContext = React.createContext()
export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)

    function signup(email, password) {
        return auth.createUserWithEmailAndPassword(email, password)
    }

    function login(email, password) {
        return auth.signInWithEmailAndPassword(email, password);
    }
    function getClaims() {
        return auth.currentUser.getIdTokenResult()
    }

    function logout() {
        return auth.signOut()
    }

    function resetPassword(email) {
        return auth.sendPasswordResetEmail(email)
    }
    function googleSignIn() {
        return auth.signInWithPopup(provider);
    }

    function updateEmail(email) {
        return currentUser.updateEmail(email)
    }

    function updatePassword(password) {
        return currentUser.updatePassword(password)
    }

    const sendPhoneVerificationCode = (phoneNumber) => {
        window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', { size: "invisible"});
        const appVerifier = window.recaptchaVerifier;
        return auth.signInWithPhoneNumber(phoneNumber, appVerifier);
    }


  const verifyPhoneCode = (verificationId, code) =>
    auth.signInWithCredential(PhoneAuthProvider.credential(verificationId, code));

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
            setLoading(false)
        })

        return unsubscribe
    }, [])

    const value = {
        currentUser,
        login,
        signup,
        logout,
        getClaims,
        resetPassword,
        updateEmail,
        updatePassword,
        googleSignIn,
        sendPhoneVerificationCode,
        verifyPhoneCode
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
