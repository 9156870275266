import "./TutorSidebar.css"
import { Link } from "react-router-dom";

const TutorSidebar = (props) => {
    return (
        <div className="sidebar">
            <img className="profile-sidebar-image" src={props.image}></img>
            <h3 className="profile-name">{props.name}</h3>
            <div className="tutor-sidebar">
                <Link to="/tutor-panel/home" className="nav_link">
                    <span className="flex-display">
                        <i className="material-icons sidebar-icon">
                            account_circle</i>
                        <h4>Profile</h4>
                    </span>
                </Link>
                <Link to="/tutor-panel/calendar" className="nav_link">
                    <span className="flex-display">
                        <i className="material-icons sidebar-icon">
                            calendar_month</i>
                        <h4>Calendar</h4>
                    </span>
                </Link>
                <Link to="/tutor-panel/students" className="nav_link">
                    <span className="flex-display">
                        <i className="material-icons sidebar-icon">
                            groups</i>
                        <h4>Students</h4>
                    </span>
                </Link>
                <Link to="/tutor-panel/classes" className="nav_link">
                    <span className="flex-display">
                        <i className="material-icons sidebar-icon">
                            history</i>
                        <h4>Class Logs</h4>
                    </span>
                </Link>
                <Link to="/tutor-panel/payments" className="nav_link">
                    <span className="flex-display">
                        <i className="material-icons sidebar-icon">
                            payments</i>
                        <h4>Payment History</h4>
                    </span>
                </Link>
            </div>
        </div>
    )
}
export default TutorSidebar;