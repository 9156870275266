import { React, useState, useEffect, api, API_URL, Row, Col, Modal } from "../../../services/common";
import StudentSidebar from '../StudentSidebar/StudentSidebar';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const StudentTutors = () => {
    const [tutors, setTutors] = useState([]);
    const [logs, setLogs] = useState(false);
    const [attended, setAttended] = useState([]);

    const handleClose = () => {
        setLogs(false);
    }
    const getClassLogs = (tutor_id) => {
        let id = localStorage.getItem('uid');
        api.get(API_URL + "/students/view-classes/" + id + "?tutor=" + tutor_id).then((response) => {
            setLogs(true);
            response.data.forEach((res) => {
                res.date = new Date(res.date).toLocaleString(undefined, { timeZone: 'Asia/Kolkata', hour12: true, hour: 'numeric', minute: 'numeric', weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
            })

            setAttended(response.data);
        })
    }
    const getTutors = () => {
        let id = localStorage.getItem('uid');
        api.get(API_URL + "/students/view-student/" + id).then((response) => {
            if (response.data.tutors) {
                setTutors(response.data.tutors);
            }
        });
    }

    function Row(props) {
        const { row } = props;
        const [open, setOpen] = React.useState(false);


        return (
            <React.Fragment>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.name}
                    </TableCell>
                    <TableCell align="right">{row.course}</TableCell>
                    <TableCell align="right">{row.class_attended} / {row.total_class}</TableCell>
                    {/* <TableCell align="right">{row.carbs}</TableCell>
          <TableCell align="right">{row.protein}</TableCell> */}
                </TableRow>
                {/* <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  History
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Customer</TableCell>
                      <TableCell align="right">Amount</TableCell>
                      <TableCell align="right">Total price ($)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.history.map((historyRow) => (
                      <TableRow key={historyRow.date}>
                        <TableCell component="th" scope="row">
                          {historyRow.date}
                        </TableCell>
                        <TableCell>{historyRow.customerId}</TableCell>
                        <TableCell align="right">{historyRow.amount}</TableCell>
                        <TableCell align="right">
                          {Math.round(historyRow.amount * row.price * 100) / 100}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow> */}
            </React.Fragment>
        );
    }

    useEffect(() => {
        getTutors();
    }, []);
    return (
        <div className="row">
            <Modal show={logs} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Attended Classes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {attended.length > 0 && attended.map((cls, index) => (
                        <Col>
                            <Row className="class_date">
                                {index + 1} . Date:  {cls.date}
                            </Row>
                            <Row className="duration">
                                Duration:  <b>{cls.mins}</b>
                            </Row>

                        </Col>
                    ))}

                </Modal.Body>
            </Modal>
            <div className="col-4">
                <StudentSidebar></StudentSidebar>
            </div>

            <div className="col-8" id="students">
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>Tutor Name</TableCell>
                                <TableCell align="right">Course</TableCell>
                                <TableCell align="right">Classes Attended</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tutors.map((tutor, index) => (
                                <Row key={index} row={tutor} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}

export default StudentTutors;
