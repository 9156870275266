// Import necessary modules
import React, { useState } from 'react';
import { useAuth } from "./AuthContext";

const PhoneAuth = () => {
  // State for storing the phone number and verification code
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const { sendPhoneVerificationCode, verifyPhoneCode } = useAuth();
  const [confirmationResult, setConfirmationResult] = useState(null);

  
  // State to track if code has been sent and if the user is signed in
  const handleSendCode = async () => {
    debugger;
    try {
       sendPhoneVerificationCode(phoneNumber).then((result) => {
        console.log(result);
        setConfirmationResult(result);
       });
      console.log(confirmationResult);
      // Save confirmation result if needed
    } catch (error) {
      console.error("Error sending verification code:", error);
    }
  };

  const handleVerifyCode = async () => {
    try {
      // Use the verification code to sign in the user
      debugger;
      console.log(confirmationResult);
   verifyPhoneCode(
        confirmationResult.verificationId,
        verificationCode
      ).then((result) => {
        // if(result){
        //     navigator('')
        // }
      });
    //   console.log(userCredential);

      // Handle successful verification
    } catch (error) {
      console.error("Error verifying code:", error);
    }
  };

  return (
    <div>
      <label>Phone Number:</label>
      <div id="recaptcha-container"></div>
      <input
        type="tel"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        required
      />
      <button onClick={handleSendCode}>Send Code</button>

      <label>Verification Code:</label>
      <input
        type="text"
        value={verificationCode}
        onChange={(e) => setVerificationCode(e.target.value)}
        required
      />
      <button onClick={handleVerifyCode}>Verify Code</button>
    </div>
  );
}
;

export default PhoneAuth;