import { React, Button } from '../../services/common'
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import person_with_guitar from "../../assets/images/person_with_guitar.png";
import person from "../../assets/images/person.png";
import person_with_drum from "../../assets/images/person_with_drum.png";
import note from "../../assets/images/note.png";
import play_button from "../../assets/images/play_button.png";
import "./About.css";



const About = () => {
    const steps = [
        {
            title: 'Book a Demo',
            img: note,
        },
        {
            title: 'Experience the class',
            img: person_with_guitar,
        },
        {
            title: 'Start your music journey',
            img: person_with_drum
        },

    ]
    return (
        <>
            <div>
                <div className="description">
                    <br></br>
                    <div id="what_why_who">
                        <h2> What?</h2>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                            dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                            proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        </p>
                        <h2> Why? </h2>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        </p>
                        <h2> Who? </h2>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        </p>
                    </div>
                    <img id="person_with_guitar" src={person_with_guitar}></img>

                </div>

                <div className="center-align">
                    <h1 >How it Works? </h1>
                    <div className="process">
                        {steps.map((step) => (
                            <div>
                                <Card className="steps" >
                                    <div className="card_photo">
                                        <Card.Img className="image-about" src={step.img} />
                                    </div>
                                </Card>
                                <h2  > {step.title} </h2>
                            </div>

                        ))}

                    </div>
                    <h2 className="or">OR</h2>
                    <a href="#">
                        <img id="play_button" src={play_button}></img>
                        <h2> Watch video </h2> </a>
                </div>
                <div className="center-align space">
                    <h1> What are you waiting for?</h1>
                    <img id="person" src={person}></img>
                    <h2> Start your music journey now!</h2>
                    <Link to="/tutors">
                        <Button id="free_demo" variant="primary">Book a free demo </Button>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default About;
