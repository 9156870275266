import { React, useState, useEffect, API_URL, api } from "../../../services/common";
import TutorSidebar from '../TutorSidebar/TutorSidebar';
import date_logo from "../../../assets/images/date_logo.png"
import time_logo from "../../../assets/images/time_logo.png"
import teacher1 from "../../../assets/images/teacher1.png"
import "./TutorStudentsLogs.css";

const TutorStudentLogs = () => {
    const [shedule, setShedule] = useState();
    return (
        <div>
            <div id="page">
                <div id="middle_part">
                    <TutorSidebar></TutorSidebar>
                    <div id="classes_part">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <div id="tutor_details">
                                            <img id="tutor_img" src={teacher1}></img>
                                            <div id="name">
                                                Student
                                                <br />
                                                Instrumrnt(s) or subject(s) enrolled
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <select id="select">
                                            <option>View only</option>
                                        </select>
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        <h2>Past <button id="btn8">8</button></h2>
                                    </td>
                                    <td>
                                        <h2>Upcoming</h2>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {shedule.map((shed) => (
                                    <tr>
                                        <td>
                                            <div id="ins_int">
                                                <div>{shed.instrument}</div>
                                                <div>{shed.course}</div>
                                                <br></br>
                                            </div>
                                            <div id="table_data">
                                                <img id="date_time_logo" src={date_logo}></img>
                                                {shed.date}
                                                <br />
                                                <img id="date_time_logo" src={time_logo}></img>
                                                {shed.timing}
                                                <br />
                                                Attendance: {shed.attendance}
                                            </div>
                                        </td>
                                        <td>
                                            <button id="class_type">{shed.class_type}</button>
                                            <br></br>
                                            <br></br>
                                            Payment Status:
                                            {shed.payment_status}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TutorStudentLogs
