import { React, useState, useEffect, Button, Modal } from "../../../services/common"
import { Link } from "react-router-dom";
import logologin from "../../../assets/images/logo-login.svg";
import logo from "../../../assets/images/logo.svg"
import "./Header.css";
import Login from "../../Auth/Login";
import Signup from "../../Auth/Signup";


const Header = () => {
    const [student, setStudent] = useState(0);
    const [tutor, setTutor] = useState(0);
    const [openLogin, setOpenLogin] = useState(false);
    const [login, setLogin] = useState(false);
    const [signup, setSignup] = useState(false);
    const closeLoginPopup = () => {
        setOpenLogin(false);
    }

    const showLogin = () => {
        setLogin(true);
        setSignup(false);
    }

    const showSignup = () => {
        debugger;
        setLogin(false);
        setSignup(true);
    }
 
    const openLoginPopup = ()=>{
        setOpenLogin(true);
        showLogin();
    }
    useEffect(() => {
        if (parseInt(localStorage.getItem('is_student')) === 0 || parseInt(localStorage.getItem('is_student')) === 1)
            setStudent(parseInt(localStorage.getItem('is_student')));
        if (parseInt(localStorage.getItem('is_tutor')) === 0 || parseInt(localStorage.getItem('is_tutor')) === 1)
            setTutor(parseInt(localStorage.getItem('is_tutor')));
    }, [])
    return (

        <>
        <div className = "header">
           <img src = {logo}></img> 
           <div className = "navbar-heading">
           <Link className = "link-heading">Start teaching</Link>
           <Link className = "link-heading">Find tutor</Link>
           <Link className = "link-heading">Schedule</Link>
           <Link className = "link-heading">Contact Us</Link>
            <Link onClick={() => openLoginPopup()} className = "login-heading link-heading">Login</Link>
           </div>
        </div>

        <Modal show={openLogin} onHide={closeLoginPopup}>
                    <Modal.Body>
                        <img src= {logologin}></img>

                        <div>
                            <p className = "welcome-back">WELCOME BACK 👋🏻</p>
                            <p className = "continue-account ">Continue to your Account.</p>
                            <div>
                            {
                                (login) && 
                                <Login showSignup={showSignup}></Login>
                             
                            }
                            </div>
                            <div>
                            {
                                (signup) && 
                                <Signup showLogin={showLogin}></Signup>
                            }

                            </div>
                            <div>
                                
                            </div>
                          
                        </div>
                    </Modal.Body>
                </Modal>

        </>
    )
}
export default Header;
